import React from 'react'
import queryString from 'query-string'

const IcelandAuth = ({
    location,
}) => {
    if (location == null || location.search == null) {
        return null
    }
    const queryParsed = queryString.parse(location.search)

    if (queryParsed && queryParsed.jwt) {
        localStorage.setItem('icelandauth', queryParsed.jwt)
        localStorage.setItem('lasticelandauth', (new Date().getTime()).toString())
    }

    return (
        <p>Augnablik</p>
    )
}

export default IcelandAuth
